import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class BlogRoll extends React.Component {
  state = {
    showAll: false,
    search: '',
    activeCategory:  { label: "All", value: "all", active: true},
    category: [
      { label: "All", value: "all", active: true},
      { label: "Therapists", value: "therapists" },
      { label: "Patients", value: "patients" },
      { label: "Physicians", value: "physicians" },
      { label: "Flexibility", value: "flexibility" },
      { label: "Independence", value: "independence" },
      { label: "Earning potential", value: "potential" },
      { label: "Tips", value: "tips" }
    ],
    posts: this.props.data.allMarkdownRemark.edges,
    total: this.props.data.allMarkdownRemark.edges.length,
    postDescriptionLines: null,
    postDescriptionHeight: 85,
    maxLines: [],
  };
  showAll = () => {
    this.setState({ showAll: true });
  };
  search = (value) => {
    this.setState({ search: value.target.value });
  };
  componentWillMount() {
    this.getPostDescriptionLines();
  }
  getPostDescriptionLines = (call) => {
    const { posts } = this.state;

    if (!posts.length) return;

    const titleLines = posts.map((post) => post.node.frontmatter.title.length);
    const max = Math.max(...titleLines);
    const postTitleLines = Math.round(max/25);

    const maxLines = titleLines.map(line => {
      const maxLine = line < 25 ? 1 : Math.round(line/25);
      const lines = (postTitleLines - maxLine);
      return {
        maxLine,
        marginBottom: lines < 0 ? 0 : lines * 16, 
      }
    });
    
    const postDescriptionLines = 5 - postTitleLines;
    const postDescriptionHeight = postDescriptionLines * 21;
    this.setState({postDescriptionLines, postDescriptionHeight, maxLines})
  };
  changeCategory(e, item) {
    e.preventDefault();
    const posts = this.props.data.allMarkdownRemark.edges.filter(({ node: i }) => {
      return (i.frontmatter.category === item.value) || item.value === 'all';
    });
    this.setState({
      activeCategory: item,
      posts,
      total: posts.length
  },  this.getPostDescriptionLines);
  };

  render() {
    return (
      <>
        {/* Begin Menu */}
        <div className="bg-gray p-0">
          <div className="container p-0">
            <ul className="blog-menu-ul">
              {
                this.state.category.map((item, i) => {
                  return (
                    <li key={i}><a onClick={(e) => this.changeCategory(e, item)} className={`blog-menu ${this.state.activeCategory.value === item.value && 'active'}`} href="#">{item.label}</a></li>
                  );
                })
              }
            </ul>
          </div>
        </div>
        {/* End Menu */}
        <div className="container mt-5 mb-4 pt-5 pb-4">
          <div className="row mb-5">
            {/* heading and search form */}
            <div className="col-md-8 ml-auto mr-auto text-center">
              <h2 className="mb-4"> Pete Health Blog </h2>
              <div className="input-group subscribe ca-shadow-sm rounded-2">
                <input type="text" className="form-control border-0 rounded-2" value={this.state.search} onChange={this.search} placeholder="Search" />
                <div className="input-group-append bg-white rounded-2">
                  <button className="btn ca-btn text-secondary bg-white" type="submit"><i className="fa fa-search" /></button>
                </div>
              </div>
            </div>
            {/* End of heading and search form */}
          </div>
          <>
            <div className="row blog-wrap">
              {
                this.state.posts && this.state.posts.map(({ node: post }, i) => {
                  if (new Date(post.frontmatter.date).getTime() > new Date().getTime()) return null;
                  if (!this.state.showAll && i > 5) return null;
                  if (post.frontmatter.title.toLowerCase().indexOf(this.state.search.toLowerCase()) === -1) return null;
                  return (
                    <div className="col-md-4" key={i}>
                      <div className="card rounded-3 border-0  mb-4 ca-shadow-sm">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          }}
                        />
                        <div className="card-body p-3 text-sm-left">
                          <h6 className="text-muted" style={{height: '12px'}}>{post.frontmatter.category}</h6>
                          <h3 style={{paddingBottom: `${this.state.maxLines[i]?.marginBottom}px`}}>{post.frontmatter.title}</h3>
                          <p className="card-text" style={{minHeight: `85px`, maxHeight: `${85}px`, overflow: "hidden", textOverflow: "elipsis", WebkitLineClamp: 4, WebkitBoxOrient: "vertical", display: "-webkit-box"}}>{post.frontmatter.description}</p>
                          <Link className="read-more" to={post.fields.slug}> <img src="/assets/img/blog-09.png" alt="" /></Link>
                        </div>
                      </div>
                    </div>
                  );
                })
              }
            </div>
            <div className="text-center mt-2 mb-5">
              {!this.state.showAll && this.state.total > 5 ?
              <button onClick={this.showAll} className="btn btn-outline-light blog-btn text-dark border-1 border-dark pl-5 pr-5 btn-sm px-5r"> See more
              </button> : null
              }
            </div>
          </>
        </div>
      </>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                description
                category
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 320, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
