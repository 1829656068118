import React from 'react';
import BlogRoll from '../components/BlogRoll';
import Layout from '../components/Layout';
import {Helmet} from "react-helmet";
import {graphql, Link, useStaticQuery} from "gatsby";

export class BlogIndexTemplate extends React.Component {
  render() {
    const data  = this.props.data
    return (
      <Layout>
        <main role="main" className="blog-page">
          <Helmet>
            <title>Blog</title>
          </Helmet>
          <div className="banner blog-banner" style={{backgroundImage: `url(${data.image.childImageSharp ? data.image.childImageSharp.fluid.src : data.image})`}} >
            <div className="container text-light">
              <div className="row">
                <div className="col-md-6" style={{color: '#00234e'}}>
                  {/*<h6 style={{marginBottom: 0, fontSize: '1rem'}}>FEATURED BLOG POST</h6>*/}
                  <br />
                  <h2 className="display-5">{data.title}</h2>
                  <p>
                    {data.description}
                  </p>
                  <Link to={data.url} className="btn btn-outline-light blog-btn text-dark border-1 border-dark pl-5 pr-5 btn-sm px-5r" style={{border: '1px solid #00234e'}} role="button">Read now
                  </Link>
                </div>
                {/*<div className="col-md-6 text-right">*/}
                {/*  <img className="comingsoonimg" style={{width: '220px', position: 'relative', bottom: '-115px', right: '-47px'}} src="./assets/img/1326.png" alt="" />*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          {
            !this.props.hidePost && <BlogRoll />
          }
          {/* End Blog grid */}
          {/* Begin Subscribe  */}
          <div className="container-fluide bg-light p-5 blog-subs">
            <div className="container text-center p-2">
              <div className="col-md-8 ml-auto mr-auto">
                <h2 className="text-center">Subscribe to get our newsletters</h2>
                <div className="input-group mt-3 mb-3 subscribe ca-shadow-sm rounded-2">
                  <input type="text" className="form-control border-0 rounded-2" placeholder="Enter your email" />
                  <div className="input-group-append bg-white rounded-2">
                    <button className="btn ca-btn btn-gr pl-4 pr-4" type="submit">Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
          </div> {/* End Subscribe */}
        </main>
      </Layout>
    )
  }
}

export default () => {
  const data = useStaticQuery(
    graphql`
  query BlogIndexTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "blog-page" } }) {
      frontmatter {
        title
        description
        url
        image {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
    }
    }
    `)
  return <BlogIndexTemplate data={data.markdownRemark.frontmatter} hidePost={false} />
}
